<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col-lg-12">
          <h4 class="text-center pt-1">{{ name }}</h4>
        </div>
      </div>
    </b-card>
    <form-wizard
      color="#3e74c7"
      :title="null"
      :subtitle="null"
      ref="wizard"
      :enable-finish-button="true"
      layout="vertical"
      finish-button-text="Frissítés"
      next-button-text="Tovább"
      next-button-icon="fa fa-arrow-right"
      back-button-text="Vissza"
      class="wizard-vertical mb-3"
      @on-complete="update"
      tabindex="3"
    >

      <!-- account datails tab -->
      <tab-content title="Személyes adatok">
        <div class="row">
          <h5 class="col-lg-6 mb-0 pb-0">Személyes adatok</h5>
          <div class="col-lg-6 text-right">
            <button class="btn btn-primary" @click="update">Frissítés</button>
          </div>
          <b-form-group
            label="Név"
            label-for="name"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="name"
              v-model="name"
              type="text"
              placeholder="Név"
            />
          </b-form-group>
          <b-form-group
            label="Lakcím"
            label-for="address"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="address"
              v-model="address"
              type="text"
              placeholder="Lakcím"
            />
          </b-form-group>
          <b-form-group
            label="Anyja neve"
            label-for="mother_name"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="mother_name"
              v-model="mother_name"
              type="text"
              placeholder="Anyja neve"
            />
          </b-form-group>
          <b-form-group
            label="Születési hely"
            label-for="birth_location"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="birth_location"
              v-model="birth_location"
              type="text"
              placeholder="Születési hely"
            />
          </b-form-group>
          <b-form-group
            label="Születési idő"
            label-for="birth_date"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="birth_date"
              v-model="birth_date"
              type="text"
              placeholder="Születési idő"
            />
          </b-form-group>
          <b-form-group
            label="Telefonszám"
            label-for="phone_number"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="phone_number"
              v-model="phone_number"
              type="text"
              placeholder="Telefonszám"
            />
          </b-form-group>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">Okmány adatok</h5>
          <b-form-group
            label="TAJ szám"
            label-for="health_insurance_number"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="health_insurance_number"
              v-model="health_insurance_number"
              type="text"
              placeholder="TAJ szám"
            />
          </b-form-group>
          <b-form-group
            label="Adóazonosító  szám"
            label-for="tax_number"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="tax_number"
              v-model="tax_number"
              type="text"
              placeholder="Adóazonosító  szám"
            />
          </b-form-group>
          <b-form-group
            label="Szem.ig. szám"
            label-for="personal_number"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="personal_number"
              v-model="personal_number"
              type="text"
              placeholder="Szem.ig. szám"
            />
          </b-form-group>
          <b-form-group
            label="Személyi szám"
            label-for="personal_number_sec"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="personal_number_sec"
              v-model="personal_number_sec"
              type="text"
              placeholder="Személyi szám"
            />
          </b-form-group>
          <b-form-group
            label="Lakcímkártya száma"
            label-for="address_of_residence"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="address_of_residence"
              v-model="address_of_residence"
              type="text"
              placeholder="Lakcímkártya száma"
            />
          </b-form-group>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">Egyéb</h5>
          <b-form-group
            label="Megjegyzés"
            label-for="comment"
            class="col-md-12 mt-1"
          >
            <b-form-textarea
              id="comment"
              v-model="comment"
              type="text"
              placeholder="Mejegyzés"
            />
          </b-form-group>
        </div>

      </tab-content>
      <tab-content title="Munka adatok">
        <div class="row">
          <h5 class="col-lg-6 mb-0 pb-0">Munkával kapcsolatos adatok</h5>
          <div class="col-lg-6 text-right">
            <button class="btn btn-primary" @click="update">Frissítés</button>
          </div>
          <b-form-group
            label="Aktív munkavállaló"
            label-for="active"
            class="col-md-3 mt-1"
          >

            <b-form-select
              id="active"
              v-model="active"
              :options="activeOptions"
            />
          </b-form-group>
          <b-form-group
            label="Cég amiben dolgozik"
            label-for="monolit"
            class="col-md-3 mt-1"
          >

            <b-form-select
              id="monolit"
              v-model="monolit"
              :options="['Monolit Profi Kft.', 'Monolit Épszer Kft.']"
              placeholder="Cég amiben dolgozik"
            />
          </b-form-group>
          <b-form-group
            label="Munkakör"
            label-for="work"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="work"
              v-model="work"
              type="text"
              placeholder="Munkakör"
            />
          </b-form-group>
          <b-form-group
            label="FEOR szám"
            label-for="feor_number"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="feor_number"
              v-model="feor_number"
              type="text"
              placeholder="FEOR szám"
            />
          </b-form-group>
          <b-form-group
            label="Munkaszerződés dátuma"
            label-for="work_contract"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="work_contract"
              v-model="work_contract"
              type="text"
              placeholder="Munkaszerződés dátuma"
            />
          </b-form-group>
          <b-form-group
            label="Munkakezdés dátuma"
            label-for="date"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="work_start"
              v-model="work_start"
              type="date" max="9999-12-31"
              placeholder="Munkakezdés dátuma"
            />
          </b-form-group>
          <b-form-group
            label="Bruttó bér"
            label-for="salary"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="salary"
              v-model="salary"
              type="text"
              placeholder="Bruttó bér"
            />
          </b-form-group>
          <b-form-group
            label="Bruttó bér (szövegesen)"
            label-for="salary_text"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="salary_text"
              v-model="salary_text"
              type="text"
              placeholder="Bruttó bér (szövegesen)"
            />
          </b-form-group>
          <b-form-group
            label="Éjszakai pótlék"
            label-for="night_supplement"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="night_supplement"
              v-model="night_supplement"
              type="text"
              placeholder="Éjszakai pótlék"
            />
          </b-form-group>
          <b-form-group
            label="Éjszakai pótlék"
            label-for="night_supplement_text"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="night_supplement_text"
              v-model="night_supplement_text"
              type="text"
              placeholder="Éjszakai pótlék (szövegesen)"
            />
          </b-form-group>
          <b-form-group
            label="Csoport azonosító szám"
            label-for="group_number"
            class="col-md-3 mt-1"
          >

            <b-form-select
              id="group_number"
              v-model="group_number"
              :options="['1', '2', '3']"
              placeholder="Csoport azonosító szám"
            />
          </b-form-group>
          <b-form-group
            label="Bankszámlaszám"
            label-for="bank_number"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="bank_number"
              v-model="bank_number"
              type="text"
              placeholder="Bankszámlaszám"
            />
          </b-form-group>
          <b-form-group
            label="Utazási támogatás"
            label-for="travel_support"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="travel_support"
              v-model="travel_support"
              type="number"
              placeholder="Utazási támogatás"
            />
          </b-form-group>
          <b-form-group
            label="Munkaidő"
            label-for="work_hour"
            class="col-md-3 mt-1"
          >

            <b-form-select
              id="work_hour"
              v-model="work_hour"
              :options="['1', '2', '3', '4', '5', '6', '7', '8']"
              type="number"
              placeholder="Munkaidő"
            />
          </b-form-group>
          <b-form-group
            label="Kilépés ideje"
            label-for="exit_time"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="exit_time"
              v-model="exit_time"
              type="date" max="9999-12-31"
              placeholder="Kilépés ideje"
            />
          </b-form-group>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">Lejáró adatok</h5>
          <b-form-group
            label="Egészségügyi alkalmassági"
            label-for="medical_fitness"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="medical_fitness"
              v-model="medical_fitness"
              type="date" max="9999-12-31"
              placeholder="Egészségügyi alkalmassági"
            />
          </b-form-group>
          <b-form-group
            label="Munkavédelmi vizsga"
            label-for="OSH"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="OSH"
              v-model="OSH"
              type="date" max="9999-12-31"
              placeholder="Munkavédelmi vizsga"
            />
          </b-form-group>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">Egyedi lejáró adatok</h5>
          <div class="col-lg-12 row">
            <b-form-group
              label="Lejáró adat megnevezése 1"
              label-for="expiring_name_1"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_name_1"
                v-model="expiring_name_1"
                type="text"
                placeholder="Lejáró adat megnevezése 1"
              />
            </b-form-group>
            <b-form-group
              label="Lejárat dátuma 1"
              label-for="expiring_date_1"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_date_1"
                v-model="expiring_date_1"
                type="date" max="9999-12-31"
                placeholder="Lejárat dátuma 1"
              />
            </b-form-group>
          </div>
          <div class="col-lg-12 row">
            <b-form-group
              label="Lejáró adat megnevezése 2"
              label-for="expiring_name_2"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_name_2"
                v-model="expiring_name_2"
                type="text"
                placeholder="Lejáró adat megnevezése 2"
              />
            </b-form-group>
            <b-form-group
              label="Lejárat dátuma 2"
              label-for="expiring_date_2"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_date_2"
                v-model="expiring_date_2"
                type="date" max="9999-12-31"
                placeholder="Lejárat dátuma 2"
              />
            </b-form-group>
          </div>
          <div class="col-lg-12 row">
            <b-form-group
              label="Lejáró adat megnevezése 3"
              label-for="expiring_name_3"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_name_3"
                v-model="expiring_name_3"
                type="text"
                placeholder="Lejáró adat megnevezése 3"
              />
            </b-form-group>
            <b-form-group
              label="Lejárat dátuma 3"
              label-for="expiring_date_3"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_date_3"
                v-model="expiring_date_3"
                type="date" max="9999-12-31"
                placeholder="Lejárat dátuma 3"
              />
            </b-form-group>
          </div>
          <div class="col-lg-12 row">
            <b-form-group
              label="Lejáró adat megnevezése 4"
              label-for="expiring_name_4"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_name_4"
                v-model="expiring_name_4"
                type="text"
                placeholder="Lejáró adat megnevezése 4"
              />
            </b-form-group>
            <b-form-group
              label="Lejárat dátuma 4"
              label-for="expiring_date_4"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_date_4"
                v-model="expiring_date_4"
                type="date" max="9999-12-31"
                placeholder="Lejárat dátuma 4"
              />
            </b-form-group>
          </div>
          <div class="col-lg-12 row">
            <b-form-group
              label="Lejáró adat megnevezése 5"
              label-for="expiring_name_5"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_name_5"
                v-model="expiring_name_5"
                type="text"
                placeholder="Lejáró adat megnevezése 5"
              />
            </b-form-group>
            <b-form-group
              label="Lejárat dátuma 5"
              label-for="expiring_date_5"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_date_5"
                v-model="expiring_date_5"
                type="date" max="9999-12-31"
                placeholder="Lejárat dátuma 5"
              />
            </b-form-group>
          </div>
          <div class="col-lg-12 row">
            <b-form-group
              label="Lejáró adat megnevezése 6"
              label-for="expiring_name_6"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_name_6"
                v-model="expiring_name_6"
                type="text"
                placeholder="Lejáró adat megnevezése 6"
              />
            </b-form-group>
            <b-form-group
              label="Lejárat dátuma 6"
              label-for="expiring_date_6"
              class="col-md-3 mt-1"
            >

              <b-form-input
                id="expiring_date_6"
                v-model="expiring_date_6"
                type="date" max="9999-12-31"
                placeholder="Lejárat dátuma 6"
              />
            </b-form-group>
          </div>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">Tanulmányok</h5>
          <b-form-group
            label="Legmagasabb iskolai végzettség"
            label-for="education"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="education"
              v-model="education"
              type="text"
              placeholder="Legmagasabb iskolai végzettség"
            />
          </b-form-group>
          <b-form-group
            label="Szakmája"
            label-for="profession"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="profession"
              v-model="profession"
              type="text"
              placeholder="Szakmája"
            />
          </b-form-group>
        </div>
      </tab-content>
      <tab-content title="Ruha méretek">
        <div class="row">
          <h5 class="col-lg-12 mb-0 pb-0">Ruha méretek</h5>
          <b-form-group
            label="Póló méret"
            label-for="shirt_size"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="shirt_size"
              v-model="shirt_size"
              type="text"
              placeholder="Póló méret"
            />
          </b-form-group>
          <b-form-group
            label="Kabát méret"
            label-for="top_clothes_size"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="top_clothes_size"
              v-model="top_clothes_size"
              type="text"
              placeholder="Kabát méret"
            />
          </b-form-group>
          <b-form-group
            label="Esőkabát méret"
            label-for="waterproof_size"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="waterproof_size"
              v-model="waterproof_size"
              type="text"
              placeholder="Esőkabát méret"
            />
          </b-form-group>
          <b-form-group
            label="Nadrág méret"
            label-for="bottom_clothes_size"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="bottom_clothes_size"
              v-model="bottom_clothes_size"
              type="text"
              placeholder="Nadrág méret"
            />
          </b-form-group>
          <b-form-group
            label="Bakancs méret"
            label-for="boots_size"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="boots_size"
              v-model="boots_size"
              type="text"
              placeholder="Bakancs méret"
            />
          </b-form-group>
          <b-form-group
            label="Csizma méret"
            label-for="shoes_size"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="shoes_size"
              v-model="shoes_size"
              type="text"
              placeholder="Csizma méret"
            />
          </b-form-group>
          <b-form-group
            label="Kiadás dátuma"
            label-for="clothes_date"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="clothes_date"
              v-model="clothes_date"
              type="date" max="9999-12-31"
              placeholder="Kiadás dátuma"
            />
          </b-form-group>
        </div>
        </tab-content>
      <tab-content title="Családi adatok">
        <div class="row">
          <h5 class="col-lg-6 mb-0 pb-0">Családi adatok</h5>
          <div class="col-lg-6 text-right">
            <button class="btn btn-primary" @click="update">Frissítés</button>
          </div>
          <b-form-group
            label="Kapcsolatban"
            label-for="partnership"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="partnership"
              v-model="partnership"
              type="text"
              placeholder="Kapcsolatban"
            />
          </b-form-group>
          <b-form-group
            label="Baleset esetén értesítendő személy neve"
            label-for="notified_person"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="notified_person"
              v-model="notified_person"
              type="text"
              placeholder="Baleset esetén értesítendő személy neve"
            />
          </b-form-group>
          <b-form-group
            label="Rokoni kapcsolat megnevezése"
            label-for="notified_person_family_relationship"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="notified_person_family_relationship"
              v-model="notified_person_family_relationship"
              type="text"
              placeholder="Rokoni kapcsolat megnevezése"
            />
          </b-form-group>
          <b-form-group
            label="Értesítendő személy telefonszáma"
            label-for="notified_person_phone"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="notified_person_phone"
              v-model="notified_person_phone"
              type="text"
              placeholder="Értesítendő személy telefonszáma"
            />
          </b-form-group>
          <b-form-group
            label="Adókedvezményt igényel"
            label-for="tax_reduction"
            class="col-md-3 mt-1"
          >

            <b-form-select
              id="tax_reduction"
              v-model="tax_reduction"
              :options="['Nincs gyereke', 'Nem nálunk veszik igénybe', 'Nálunk veszik igénybe havi lebontásban', 'Nálunk veszik igénybe éves egyösszegben']"
              type="text"
              @change="tax_reduction_price = null"
            />
          </b-form-group>
          <b-form-group
            v-if="tax_reduction === 'Nálunk veszik igénybe havi lebontásban'"
            label="25 év alatti / családi kedvezmény"
            label-for="tax_reduction_price"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="tax_reduction_price"
              v-model="tax_reduction_price"
              type="number"
              placeholder="25 év alatti / családi kedvezmény"
            />
          </b-form-group>
          <b-form-group
            label="Kiskorú gyermekek száma (szabadsághoz):"
            label-for="underage"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="underage"
              v-model="underage"
              type="text"
              placeholder="Kiskorú gyermekek száma (szabadsághoz):"
            />
          </b-form-group>
          <b-form-group
            label="Közeli hozzátartozója meghalt idén"
            label-for="close_relative_died"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="close_relative_died"
              v-model="close_relative_died"
              type="number"
              placeholder="Közeli hozzátartozók száma"
            />
          </b-form-group>
          <b-form-group
            label="Gyerek szültett idén"
            class="col-md-12 mt-1"
          >
            <b-form-checkbox v-model="born_children" />
          </b-form-group>
          <b-form-group
            v-if="born_children"
            label="Ikrek születtek"
            class="col-md-12 mt-1"
          >
            <b-form-checkbox v-model="born_twins" />
          </b-form-group>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">1. Gyermek</h5>
          <b-form-group
            label="Gyermek neve"
            label-for="children_name_1"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_name_1"
              v-model="children_name_1"
              type="text"
              placeholder="Gyermek neve"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési idő:"
            label-for="children_birth_date_1"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_date_1"
              v-model="children_birth_date_1"
              type="date" max="9999-12-31"
              placeholder="Gyermek születési idő:"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési helye"
            label-for="children_birth_place_1"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_place_1"
              v-model="children_birth_place_1"
              type="text"
              placeholder="Gyermek születési helye"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek TAJ szám"
            label-for="children_health_insurance_number_1"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_health_insurance_number_1"
              v-model="children_health_insurance_number_1"
              type="text"
              placeholder="Gyermek TAJ szám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek adószám"
            label-for="children_tax_number_1"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_tax_number_1"
              v-model="children_tax_number_1"
              type="text"
              placeholder="Gyermek adószám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek anyja neve"
            label-for="children_mother_name_1"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_mother_name_1"
              v-model="children_mother_name_1"
              type="text"
              placeholder="Gyermek anyja neve"
            />
          </b-form-group>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">2. Gyermek</h5>
          <b-form-group
            label="Gyermek neve"
            label-for="children_name_2"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_name_2"
              v-model="children_name_2"
              type="text"
              placeholder="Gyermek neve"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési idő:"
            label-for="children_birth_date_2"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_date_2"
              v-model="children_birth_date_2"
              type="date" max="9999-12-31"
              placeholder="Gyermek születési idő:"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési helye"
            label-for="children_birth_place_2"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_place_1"
              v-model="children_birth_place_2"
              type="text"
              placeholder="Gyermek születési helye"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek TAJ szám"
            label-for="children_health_insurance_number_2"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_health_insurance_number_2"
              v-model="children_health_insurance_number_2"
              type="text"
              placeholder="Gyermek TAJ szám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek adószám"
            label-for="children_tax_number_2"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_tax_number_2"
              v-model="children_tax_number_2"
              type="text"
              placeholder="Gyermek adószám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek anyja neve"
            label-for="children_mother_name_2"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="children_mother_name_2"
              v-model="children_mother_name_2"
              type="text"
              placeholder="Gyermek anyja neve"
            />
          </b-form-group>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">3. Gyermek</h5>
          <b-form-group
            label="Gyermek neve"
            label-for="children_name_3"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_name_3"
              v-model="children_name_3"
              type="text"
              placeholder="Gyermek neve"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési idő:"
            label-for="children_birth_date_3"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_date_3"
              v-model="children_birth_date_3"
              type="date" max="9999-12-31"
              placeholder="Gyermek születési idő:"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési helye"
            label-for="children_birth_place_3"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_place_3"
              v-model="children_birth_place_3"
              type="text"
              placeholder="Gyermek születési helye"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek TAJ szám"
            label-for="children_health_insurance_number_3"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_health_insurance_number_3"
              v-model="children_health_insurance_number_3"
              type="text"
              placeholder="Gyermek TAJ szám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek adószám"
            label-for="children_tax_number_3"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_tax_number_3"
              v-model="children_tax_number_3"
              type="text"
              placeholder="Gyermek adószám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek anyja neve"
            label-for="children_mother_name_3"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_mother_name_3"
              v-model="children_mother_name_3"
              type="text"
              placeholder="Gyermek anyja neve"
            />
          </b-form-group>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">4. Gyermek</h5>
          <b-form-group
            label="Gyermek neve"
            label-for="children_name_4"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_name_4"
              v-model="children_name_4"
              type="text"
              placeholder="Gyermek neve"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési idő:"
            label-for="children_birth_date_4"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_date_4"
              v-model="children_birth_date_4"
              type="date" max="9999-12-31"
              placeholder="Gyermek születési idő:"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési helye"
            label-for="children_birth_place_4"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_place_4"
              v-model="children_birth_place_4"
              type="text"
              placeholder="Gyermek születési helye"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek TAJ szám"
            label-for="children_health_insurance_number_4"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_health_insurance_number_4"
              v-model="children_health_insurance_number_4"
              type="text"
              placeholder="Gyermek TAJ szám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek adószám"
            label-for="children_tax_number_4"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_tax_number_4"
              v-model="children_tax_number_4"
              type="text"
              placeholder="Gyermek adószám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek anyja neve"
            label-for="children_mother_name_4"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="children_mother_name_4"
              v-model="children_mother_name_4"
              type="text"
              placeholder="Gyermek anyja neve"
            />
          </b-form-group>
          <hr class="col-lg-12">
          <h5 class="col-lg-12 mb-0 pb-0">5. Gyermek</h5>
          <b-form-group
            label="Gyermek neve"
            label-for="children_name_5"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_name_5"
              v-model="children_name_5"
              type="text"
              placeholder="Gyermek neve"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési idő:"
            label-for="children_birth_date_5"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_date_5"
              v-model="children_birth_date_5"
              type="date" max="9999-12-31"
              placeholder="Gyermek születési idő:"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek születési helye"
            label-for="children_birth_place_5"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_birth_place_5"
              v-model="children_birth_place_5"
              type="text"
              placeholder="Gyermek születési helye"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek TAJ szám"
            label-for="children_health_insurance_number_5"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_health_insurance_number_5"
              v-model="children_health_insurance_number_5"
              type="text"
              placeholder="Gyermek TAJ szám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek adószám"
            label-for="children_tax_number_5"
            class="col-md-3 mt-1"
          >

            <b-form-input
              id="children_tax_number_5"
              v-model="children_tax_number_5"
              type="text"
              placeholder="Gyermek adószám"
            />
          </b-form-group>
          <b-form-group
            label="Gyermek anyja neve"
            label-for="children_mother_name_5"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="children_mother_name_5"
              v-model="children_mother_name_5"
              type="text"
              placeholder="Gyermek anyja neve"
            />
          </b-form-group>
        </div>
      </tab-content>
      <tab-content title="Karrier">
        <div class="row">
          <h5 class="col-lg-6 mb-0 pb-0">Karrier adatok</h5>
          <div class="col-lg-6 text-right">
            <button class="btn btn-primary" @click="update">Frissítés</button>
          </div>
          <div class="col-lg-12 mt-2">
            <table class="table">
              <tr>
                <th>
                  <b>Cég</b>
                </th>
                <th>
                  <b>Beosztás</b>
                </th>
                <th>
                  <b>Kezdés</b>
                </th>
                <th>
                  <b>Befejezés</b>
                </th>
              </tr>
              <tr v-for="carrier in carrier_data" :key="carrier">
                <td>{{ carrier.monolit }}</td>
                <td>{{ carrier.work }}</td>
                <td>{{ carrier.work_start }}</td>
                <td>{{ carrier.exit_time }}</td>
              </tr>
            </table>
          </div>
        </div>
      </tab-content>
      <tab-content title="Egyedi adatok">
        <div class="row">
          <h5 class="col-lg-12 mb-0 pb-0">Egyedi adatok</h5>
          <b-form-group
            v-for="(item, index) in extra_fields_list"
            :key="index"
            :label="item.name"
            :label-for="item.unicode_name"
            class="col-md-3 mt-1"
          >
            <b-form-input
              :id="item.unicode_name"
              v-model="extra_fields[item.unicode_name]"
              type="text"
              :placeholder="item.name"
            />
          </b-form-group>
        </div>
      </tab-content>
      <tab-content title="Dokumentumok">
        <div class="row">
          <h5 class="col-lg-6 mb-2 pb-0">Alkalmazott dokumentumjai</h5>
          <div class="col-lg-6 mb-2 pb-0 text-right">
            <button @click="$bvModal.show('generate_document')" class="btn btn-primary">Létrehozás</button>
          </div>
          <div class="col-lg-12">
            <table class="table ">
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Dokumentum neve</td>
                  <td>Fájl neve</td>
                  <td>Kiállítás dátuma</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(document, index) in rows" :key="index">
                  <td>{{ document.id }}</td>
                  <td>{{ document.document_name }}</td>
                  <td>{{ document.name }}</td>
                  <td>{{ document.created_at }}</td>
                  <td class="text-right">
                    <button class="btn btn-primary" @click="downloadDocument(document.id, document.document_name)">Letöltés</button>
                    <button class="btn btn-danger ml-1" @click="deleteDocument(document.id)">Törlés</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </tab-content>
    </form-wizard>
    <b-modal id="generate_document" title="Dokumentum generálása" size="sm" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-form-group
          label="Dokumentum megnevezése"
          label-for="document_type"
          class="col-lg-12"
        >
          <b-form-select
            id="document_type"
            v-model="document_type"
            :options="document_types"
            placeholder="Dokumentum megnevezése"
          />
        </b-form-group>
        <b-form-group class="col-lg-12" v-if="document_type == 'contract_modification'">
          <small>Érvényesség</small>
          <b-form-input
            id="document_type"
            v-model="contract_modification_date"
            type="date"
            placeholder="Mikortól érvényes?"
          />
        </b-form-group>
        <b-form-group class="col-lg-12" v-if="document_type == 'contract_modification'">
          <small>Szerződés kelte</small>
          <b-form-input
            id="document_type"
            v-model="contract_modification_create"
            type="date"
            placeholder="Szerződés kelte"
          />
        </b-form-group>
        <button class="btn btn-primary col-lg-12 mt-2" @click="generate">Generálás</button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BCard,
  BFormCheckbox,
  BModal,
  BPagination,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    VueGoodTable,
    BFormCheckbox,
    FormWizard,
    TabContent,
    BCard,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  /* watch: {
    work_number(val) {
      this.getDataByWorkNumber(val)
    },
  }, */
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  data() {
    return {
      document_types: [
        {
          text: 'GDPR',
          value: 'gdpr',
        },
        {
          text: 'Munkaszerződés módosítás',
          value: 'contract_modification',
        },
        {
          text: 'Munkaszerződés',
          value: 'contract_employee',
        },
        {
          text: 'Adategyeztető lap',
          value: 'contract_data_reconciliation_sheet',
        },
      ],
      document_type: '',
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Dokumentum neve',
          field: 'document_name',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés dokumentumra',
          },
        },
        {
          label: 'Dokumentum neve a szerveren',
          field: 'name',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés dátumra',
          },
        },
        {
          label: 'Létrehozás dátuma',
          field: 'created_at',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés dátumra',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      contract_modification_date: '',
      contract_modification_create: '',
      selectedData: {},
      close_relative_died: false,
      born_twins: false,
      born_children: false,
      extra_fields: {},
      name: '',
      address: '',
      mother_name: '',
      birth_location: '',
      birth_date: '',
      health_insurance_number: '',
      tax_number: '',
      travel_support: '',
      personal_number: '',
      personal_number_sec: '',
      address_of_residence: '',
      phone_number: '',
      work: '',
      feor_number: '',
      work_contract: '',
      work_start: '',
      salary: '',
      salary_text: '',
      night_supplement: '',
      night_supplement_text: '',
      medical_fitness: '',
      OSH: '',
      exit_time: '',
      comment: '',
      education: '',
      profession: '',
      boots_size: '',
      shoes_size: '',
      top_clothes_size: '',
      bottom_clothes_size: '',
      waterproof_size: '',
      bank_number: '',
      active: true,
      activeOptions: [
        { text: 'Aktív', value: true },
        { text: 'Inaktív', value: false },
      ],
      monolit: '',
      partnership: '',
      notified_person: '',
      notified_person_family_relationship: '',
      notified_person_phone: '',
      tax: '',
      tax_reduction: '',
      tax_reduction_price: '',
      underage: '',
      children_name_1: '',
      children_birth_date_1: '',
      children_birth_place_1: '',
      children_health_insurance_number_1: '',
      children_tax_number_1: '',
      children_mother_name_1: '',
      children_name_2: '',
      children_birth_date_2: '',
      children_birth_place_2: '',
      children_health_insurance_number_2: '',
      children_tax_number_2: '',
      children_mother_name_2: '',
      children_name_3: '',
      children_birth_date_3: '',
      children_birth_place_3: '',
      children_health_insurance_number_3: '',
      children_tax_number_3: '',
      children_mother_name_3: '',
      children_name_4: '',
      children_birth_date_4: '',
      children_birth_place_4: '',
      children_health_insurance_number_4: '',
      children_tax_number_4: '',
      children_mother_name_4: '',
      children_name_5: '',
      children_birth_date_5: '',
      children_birth_place_5: '',
      children_health_insurance_number_5: '',
      children_tax_number_5: '',
      children_mother_name_5: '',
      extra_fields_list: {},
      shirt_size: '',
      clothes_date: '',
      group_number: '',
      underage_learn: '',
      expiring_name_1: '',
      expiring_date_1: '',
      expiring_name_2: '',
      expiring_date_2: '',
      expiring_name_3: '',
      expiring_date_3: '',
      expiring_name_4: '',
      expiring_date_4: '',
      expiring_name_5: '',
      expiring_date_5: '',
      expiring_name_6: '',
      expiring_date_6: '',
      work_hour: '',
      carrier_data: [],
    }
  },
  mounted() {
    this.getExtraFields()
    this.getEmployee()
    this.getDocument()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
  },
  methods: {
    deleteDocument(id) {
      axios({
        method: 'delete',
        url: `employee/document/delete/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dokumentum sikeresen törölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getDocument()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hiba a törlés során',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    downloadDocument(id, document_name) {
      axios({
        method: 'post',
        url: `employee/document/download/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.name} ${document_name}.pdf`)
        document.body.appendChild(link)
        link.click()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hibás a fájl!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    generate() {
      axios({
        method: 'post',
        url: `employee/document/create/${this.document_type}/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          contract_modification_create: this.contract_modification_create,
          contract_modification_date: this.contract_modification_date,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        if (this.document_type === 'gdpr') {
          link.setAttribute('download', `${this.name} GDPR.pdf`)
        } else if (this.document_type === 'contract_modification') {
          link.setAttribute('download', `${this.name} szerződés módosítás.pdf`)
        } else if (this.document_type === 'contract') {
          link.setAttribute('download', `${this.name} szerződés.pdf`)
        } else if (this.document_type === 'contract_data_reconciliation_sheet') {
          link.setAttribute('download', `${this.name} Adategyeztető lap.pdf`)
        }else{
          link.setAttribute('download', `${this.name} dokumentum.pdf`)
        }
        document.body.appendChild(link)
        link.click()
        this.getDocument()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getDocument() {
      axios({
        method: 'get',
        url: `employee/document/list/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
      })
    },
    // eslint-disable-next-line camelcase
    getCarrierData(tax_number) {
      axios({
        method: 'get',
        // eslint-disable-next-line camelcase
        url: `employee/carrier/${tax_number}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.carrier_data = response.data
      })
    },
    getExtraFields() {
      axios({
        method: 'get',
        url: 'extra-fields/list/Alkalmazott nyilvántartó',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.extra_fields_list = response.data
        this.extra_fields_list.forEach(item => {
          this.extra_fields[item.unicode_name] = ''
        })
      })
    },
    getEmployee() {
      axios({
        method: 'get',
        url: `employee/get/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.name = res.data.name
        this.address = res.data.address
        this.mother_name = res.data.mother_name
        this.close_relative_died = res.data.close_relative_died
        this.born_twins = res.data.born_twins
        this.born_children = res.data.born_children
        this.birth_location = res.data.birth_location
        this.birth_date = res.data.birth_date
        this.health_insurance_number = res.data.health_insurance_number
        this.tax_number = res.data.tax_number
        this.personal_number = res.data.personal_number
        this.personal_number_sec = res.data.personal_number_sec
        this.address_of_residence = res.data.address_of_residence
        this.phone_number = res.data.phone_number
        this.work = res.data.work
        this.feor_number = res.data.feor_number
        this.work_contract = res.data.work_contract
        this.work_start = res.data.work_start
        this.salary = res.data.salary
        this.salary_text = res.data.salary_text
        this.night_supplement = res.data.night_supplement
        this.night_supplement_text = res.data.night_supplement_text
        this.medical_fitness = res.data.medical_fitness
        this.OSH = res.data.OSH
        this.exit_time = res.data.exit_time
        this.comment = res.data.comment
        this.education = res.data.education
        this.profession = res.data.profession
        this.boots_size = res.data.boots_size
        this.shoes_size = res.data.shoes_size
        this.tax_reduction_price = res.data.tax_reduction_price
        this.top_clothes_size = res.data.top_clothes_size
        this.bottom_clothes_size = res.data.bottom_clothes_size
        this.waterproof_size = res.data.waterproof_size
        this.bank_number = res.data.bank_number
        this.partnership = res.data.partnership
        this.notified_person = res.data.notified_person
        this.notified_person_family_relationship = res.data.notified_person_family_relationship
        this.notified_person_phone = res.data.notified_person_phone
        this.tax = res.data.tax
        this.tax_reduction = res.data.tax_reduction
        this.underage = res.data.underage
        this.travel_support = res.data.travel_support
        this.children_name_1 = res.data.children_name_1
        this.children_birth_date_1 = res.data.children_birth_date_1
        this.children_birth_place_1 = res.data.children_birth_place_1
        this.children_health_insurance_number_1 = res.data.children_health_insurance_number_1
        this.children_tax_number_1 = res.data.children_tax_number_1
        this.children_mother_name_1 = res.data.children_mother_name_1
        this.children_name_2 = res.data.children_name_2
        this.children_birth_date_2 = res.data.children_birth_date_2
        this.children_birth_place_2 = res.data.children_birth_place_2
        this.children_health_insurance_number_2 = res.data.children_health_insurance_number_2
        this.children_tax_number_2 = res.data.children_tax_number_2
        this.children_mother_name_2 = res.data.children_mother_name_2
        this.children_name_3 = res.data.children_name_3
        this.children_birth_date_3 = res.data.children_birth_date_3
        this.children_birth_place_3 = res.data.children_birth_place_3
        this.children_health_insurance_number_3 = res.data.children_health_insurance_number_3
        this.children_tax_number_3 = res.data.children_tax_number_3
        this.children_mother_name_3 = res.data.children_mother_name_3
        this.children_name_4 = res.data.children_name_4
        this.children_birth_date_4 = res.data.children_birth_date_4
        this.children_birth_place_4 = res.data.children_birth_place_4
        this.children_health_insurance_number_4 = res.data.children_health_insurance_number_4
        this.children_tax_number_4 = res.data.children_tax_number_4
        this.children_mother_name_4 = res.data.children_mother_name_4
        this.children_name_5 = res.data.children_name_5
        this.children_birth_date_5 = res.data.children_birth_date_5
        this.children_birth_place_5 = res.data.children_birth_place_5
        this.children_health_insurance_number_5 = res.data.children_health_insurance_number_5
        this.children_tax_number_5 = res.data.children_tax_number_5
        this.children_mother_name_5 = res.data.children_mother_name_5
        this.extra_fields = res.data.extra_fields
        this.active = res.data.active
        this.monolit = res.data.monolit
        this.shirt_size = res.data.shirt_size
        this.clothes_date = res.data.clothes_date
        this.group_number = res.data.group_number
        this.underage_learn = res.data.underage_learn
        this.expiring_date_1 = res.data.expiring_date_1
        this.expiring_date_2 = res.data.expiring_date_2
        this.expiring_date_3 = res.data.expiring_date_3
        this.expiring_date_4 = res.data.expiring_date_4
        this.expiring_date_5 = res.data.expiring_date_5
        this.expiring_date_6 = res.data.expiring_date_6
        this.expiring_name_1 = res.data.expiring_name_1
        this.expiring_name_2 = res.data.expiring_name_2
        this.expiring_name_3 = res.data.expiring_name_3
        this.expiring_name_4 = res.data.expiring_name_4
        this.expiring_name_5 = res.data.expiring_name_5
        this.expiring_name_6 = res.data.expiring_name_6
        this.work_hour = res.data.work_hour
        this.getCarrierData(this.tax_number)
      })
    },
    update() {
      axios({
        method: 'put',
        url: `employee/update/${this.$route.params.id}`,
        data: {
          name: this.name,
          address: this.address,
          mother_name: this.mother_name,
          birth_location: this.birth_location,
          birth_date: this.birth_date,
          health_insurance_number: this.health_insurance_number,
          tax_number: this.tax_number,
          personal_number: this.personal_number,
          personal_number_sec: this.personal_number_sec,
          address_of_residence: this.address_of_residence,
          phone_number: this.phone_number,
          work: this.work,
          feor_number: this.feor_number,
          work_contract: this.work_contract,
          close_relative_died: this.close_relative_died,
          born_twins: this.born_twins,
          born_children: this.born_children,
          work_start: this.work_start,
          salary: this.salary,
          salary_text: this.salary_text,
          night_supplement: this.night_supplement,
          night_supplement_text: this.night_supplement_text,
          medical_fitness: this.medical_fitness,
          tax_reduction_price: this.tax_reduction_price,
          travel_support: this.travel_support,
          OSH: this.OSH,
          exit_time: this.exit_time,
          comment: this.comment,
          education: this.education,
          profession: this.profession,
          boots_size: this.boots_size,
          shoes_size: this.shoes_size,
          top_clothes_size: this.top_clothes_size,
          bottom_clothes_size: this.bottom_clothes_size,
          bank_number: this.bank_number,
          waterproof_size: this.waterproof_size,
          partnership: this.partnership,
          notified_person: this.notified_person,
          notified_person_family_relationship: this.notified_person_family_relationship,
          notified_person_phone: this.notified_person_phone,
          tax: this.tax,
          tax_reduction: this.tax_reduction,
          underage: this.underage,
          children_name_1: this.children_name_1,
          children_birth_date_1: this.children_birth_date_1,
          children_birth_place_1: this.children_birth_place_1,
          children_health_insurance_number_1: this.children_health_insurance_number_1,
          children_tax_number_1: this.children_tax_number_1,
          children_mother_name_1: this.children_mother_name_1,
          children_name_2: this.children_name_2,
          children_birth_date_2: this.children_birth_date_2,
          children_birth_place_2: this.children_birth_place_2,
          children_health_insurance_number_2: this.children_health_insurance_number_2,
          children_tax_number_2: this.children_tax_number_2,
          children_mother_name_2: this.children_mother_name_2,
          children_name_3: this.children_name_3,
          children_birth_date_3: this.children_birth_date_3,
          children_birth_place_3: this.children_birth_place_3,
          children_health_insurance_number_3: this.children_health_insurance_number_3,
          children_tax_number_3: this.children_tax_number_3,
          children_mother_name_3: this.children_mother_name_3,
          children_name_4: this.children_name_4,
          children_birth_date_4: this.children_birth_date_4,
          children_birth_place_4: this.children_birth_place_4,
          children_health_insurance_number_4: this.children_health_insurance_number_4,
          children_tax_number_4: this.children_tax_number_4,
          children_mother_name_4: this.children_mother_name_4,
          children_name_5: this.children_name_5,
          children_birth_date_5: this.children_birth_date_5,
          children_birth_place_5: this.children_birth_place_5,
          children_health_insurance_number_5: this.children_health_insurance_number_5,
          children_tax_number_5: this.children_tax_number_5,
          children_mother_name_5: this.children_mother_name_5,
          extra_fields: this.extra_fields,
          monolit: this.monolit,
          active: this.active,
          shirt_size: this.shirt_size,
          clothes_date: this.clothes_date,
          group_number: this.group_number,
          underage_learn: this.underage_learn,
          expiring_name_1: this.expiring_name_1,
          expiring_date_1: this.expiring_date_1,
          expiring_name_2: this.expiring_name_2,
          expiring_date_2: this.expiring_date_2,
          expiring_name_3: this.expiring_name_3,
          expiring_date_3: this.expiring_date_3,
          expiring_name_4: this.expiring_name_4,
          expiring_date_4: this.expiring_date_4,
          expiring_name_5: this.expiring_name_5,
          expiring_date_5: this.expiring_date_5,
          expiring_name_6: this.expiring_name_6,
          expiring_date_6: this.expiring_date_6,
          work_hour: this.work_hour,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cég, Név, Aktivitás megadása kötelező!',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .flatpickr-input{
    display: none !important;
  }
</style>

<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .autosuggest__results-container{
    background: var(--primary);
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .autosuggest__results-item{
    list-style: square;
    margin-top: 3px;
  }
</style>
